import { StaticImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import { ALButton } from "../ALComponents";
import * as Styles from "./freeGiftBanner/styles.module.scss";

function FreeGiftBanner({ openGiftSelection }) {
  return (
    <div className={Styles.freeGiftBanner}>
      <StaticImage
        src="../../images/free-gift-banner.png"
        alt="Free Gift Banner"
        className={Styles.freeGiftBanner__image}
      />
      <div className={Styles.freeGiftBanner__content}>
        <p className="subtext subtext--uc bold">
          <span>FREE GIFT</span> UNLOCKED
        </p>
        <ALButton className={Styles.freeGiftBanner__content_button} onClick={openGiftSelection}>
          Choose your gift
        </ALButton>
      </div>
    </div>
  );
}

FreeGiftBanner.propTypes = {
  openGiftSelection: PropTypes.func.isRequired,
};

export default FreeGiftBanner;
