import { useState, useEffect, useMemo, useContext } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import useDiscountsInfo from "../../hooks/useDiscountsInfo";
import { DiscountContext } from "../../context/DiscountProvider";
import { formatEngravableText } from "../../context/helpers";
import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import ProductReviewRating from "./ProductReviewRating";
import VariantSelectors from "./VariantSelectors";
import PriceContainer from "../PriceContainer";
import ProductEngraving from "./productSummary/ProductEngraving";
import ProductShipping from "./productSummary/ProductShipping";
import AddToCart from "./productSummary/AddToCart";
import OptionalProducts from "./productSummary/OptionalProducts";
import ProductAlternatives from "../ProductAlternatives";
import ProductMention from "../ProductMention";
import ButtonAddToBundle from "../byobCollection/ButtonAddToBundle";
import QuickUpsell from "./productSummary/QuickUpsell";
import { ComponentType } from "../../constants/ComponentType";
import { Country } from "../../constants/Country";
import { HandleType } from "../../constants/HandleType";
import DISCOUNT_TYPES from "../../constants/DiscountTypes";
import RESTRICTED_HTS_PER_COUNTRY from "../../constants/RestrictedHtsPerCountry";
import GiftWithPurchaseInfo from "./GiftWithPurchaseInfo";

// Hardcode the number charm product handles, to handle specific cases for these products
const NUMBER_CHARM_PRODUCTS = ["number-necklace-number-necklace-bundle", "number-charm"];

const getStepPrefix = (product, type) => {
  const hasAlternatives = product?.productAlternatives?.alternatives?.length > 0;
  const hasVariants = product?.variants?.length > 1;

  if (type === "alternatives" && hasVariants) {
    return "1. ";
  }
  if (type === "variants" && hasAlternatives) {
    return "2. ";
  }

  return "";
};

function ProductSummary({
  product,
  countryCode,
  selectedVariant,
  variantSelectedOptions,
  componentType,
  onVariantOptionChange,
  searchObject,
  variantsSelectorRef,
  prices,
  pricesLoading,
  onReviewsClick = () => {},
  optionalProducts = [],
  setOptionalProducts,
}) {
  const { localizedDiscount } = useContext(DiscountContext);
  const discountsInfo = useDiscountsInfo();
  const giftDiscount =
    (discountsInfo || []).find((s) => s.type === DISCOUNT_TYPES.GIFT_WITH_PURCHASE) || null;
  const [engravableText, setEngravableText] = useState("");
  const [engravableFont, setEngravableFont] = useState("");

  const isEngravable = useMemo(() => product?.engravable?.isEngravable, [product]);

  useEffect(() => {
    // Engravable
    if (isEngravable && product?.engravable?.fonts?.length === 1 && engravableFont === "") {
      // Only one font, set it by default
      setEngravableFont(product.engravable.fonts[0]);
    }
  }, [product, engravableFont, isEngravable]);

  if (!product) return null;

  const { handle, title, variants, seoContent } = product;

  const isNumberCharmProduct = NUMBER_CHARM_PRODUCTS.includes(handle);
  const showAddToBundle = componentType === ComponentType.BYOB_PDP;

  const onEngravableInputChange = (e, maxChar) => {
    setEngravableText(formatEngravableText(e.target.value, maxChar));
  };

  const isPurchaseRestricted =
    (countryCode !== Country.US && handle === HandleType.GIFT_CARD) ||
    !!(RESTRICTED_HTS_PER_COUNTRY[countryCode] || []).find((code) =>
      product?.hts?.startsWith(code)
    );

  const canBePurchased = !isPurchaseRestricted && componentType !== ComponentType.BYOB_PDP;
  const showShippingInfo = canBePurchased && !ComponentType.BYOB_PDP !== componentType;

  const productMentionData = {
    id: componentType === ComponentType.QUICKVIEW ? product?.id : product.shopifyId,
    tags: product.tags,
    isPreOrder: product.isPreOrder,
    preOrderShippingDate: product.preOrderShippingDate,
    type: product.productType,
  };

  const isGiftProduct =
    giftDiscount &&
    giftDiscount?.freegift?.products?.some((giftProduct) => giftProduct.handle === handle);

  const showQuickUpsell =
    product.productType === "Necklaces" &&
    !product.tags.includes("Type:Accessories") &&
    !product.tags.includes("Components:Solid Gold") &&
    !isGiftProduct;

  return (
    <div className="container_products_details">
      <Helmet>
        <script
          type="text/javascript"
          async
          src={`https://apps.bazaarvoice.com/deployments/analuisa/main_site/${process.env.GATSBY_BAZAARVOICE_ENV}/en_US/bv.js`}
        />
      </Helmet>
      <ProductMention
        prices={prices}
        product={productMentionData}
        globalProductMention={localizedDiscount?.[0]?.globalProductText}
        componentType={componentType}
      />
      <div
        className={`pdp_details_container pdp_details_container_${componentType?.toLowerCase()}`}
      >
        <div className="pdp_details_container_title">
          {seoContent && seoContent?.metaTitle ? (
            <>
              <h1 style={{ display: "none" }}>{seoContent?.metaTitle}</h1>
              <div className="pdp_details_container_title_heading">
                <span className="pdp_details_container_subname">
                  {title?.split(" - ")[0] || title}
                </span>
                <span style={{ display: "none" }}>&nbsp;</span>
                <span className="pdp_details_container_name">
                  {title?.split(" - ")[1] || title}
                </span>
              </div>
            </>
          ) : (
            <h1 className="pdp_details_container_title_heading">
              <span className="pdp_details_container_subname">
                {title?.split(" - ")[0] || title}
              </span>
              <span style={{ display: "none" }}>&nbsp;</span>
              <span className="pdp_details_container_name">{title?.split(" - ")[1] || title}</span>
            </h1>
          )}

          <ProductReviewRating
            type={componentType}
            onClick={onReviewsClick}
            productId={product.shopifyId}
          />
        </div>

        {canBePurchased && (
          <PriceContainer
            productId={componentType === ComponentType.QUICKVIEW ? product?.id : product.shopifyId}
            prices={prices}
            pricesLoading={pricesLoading}
            componentType={componentType}
            tags={product?.tags}
          />
        )}
      </div>

      {isGiftProduct &&
        (componentType === ComponentType.PDP ||
          componentType === ComponentType.QUICKVIEW ||
          componentType === ComponentType.PDP_ADS) && (
          <GiftWithPurchaseInfo isGiftProduct={isGiftProduct} componentType={componentType} />
        )}

      {isEngravable && (
        <ProductEngraving
          fonts={product.engravable.fonts}
          numberOfCharacters={product.engravable.numberOfCharacters}
          engravableText={engravableText}
          engravableFont={engravableFont}
          handleInputChange={onEngravableInputChange}
          setFont={setEngravableFont}
        />
      )}

      {isPurchaseRestricted && <p>This product is only available to US customers.</p>}

      <div
        className={`variants_container variants_container_${componentType?.toLowerCase()} ${
          isGiftProduct ? "no-border" : ""
        }`}
      >
        {product?.productAlternatives && (
          <ProductAlternatives
            handle={handle}
            productAlternatives={product.productAlternatives}
            componentType={componentType}
            stepPrefix={isNumberCharmProduct ? getStepPrefix(product, "alternatives") : ""}
          />
        )}

        {variants?.length > 1 && (
          <div className="variants_selector" ref={variantsSelectorRef}>
            {product?.options?.map((opts) => (
              <div className="pdp_details_variants" key={`product_options_${product.id}`}>
                <VariantSelectors
                  onChange={onVariantOptionChange}
                  options={opts}
                  productType={product?.productType}
                  currentSelection={variantSelectedOptions}
                  handle={handle}
                  country={countryCode}
                  variants={opts.values.map((t) => product.variants.find((v) => v.title === t))}
                  componentType={componentType}
                  stepPrefix={isNumberCharmProduct ? getStepPrefix(product, "variants") : ""}
                  tags={product?.tags}
                />
              </div>
            ))}
          </div>
        )}
      </div>

      {componentType === ComponentType.PDP && product.optionalProducts && (
        <OptionalProducts
          productTitle={product.title}
          selectedProducts={optionalProducts}
          optionalProductsConfig={product.optionalProducts}
          handleSetProducts={setOptionalProducts}
          handleRemoveProduct={(productToRemove) => {
            setOptionalProducts(optionalProducts.filter((p) => p.id !== productToRemove.id));
          }}
        />
      )}
      {showQuickUpsell && (
        <QuickUpsell
          tags={product.tags}
          optionalProducts={optionalProducts}
          setOptionalProducts={setOptionalProducts}
        />
      )}

      {showAddToBundle && (
        <ButtonAddToBundle
          product={product}
          selectedVariant={variantSelectedOptions}
          componentType={ComponentType.BYOB_PDP}
        />
      )}

      {giftDiscount &&
        !isGiftProduct &&
        (componentType === ComponentType.PDP ||
          componentType === ComponentType.QUICKVIEW ||
          componentType === ComponentType.PDP_ADS) && (
          <GiftWithPurchaseInfo isGiftProduct={isGiftProduct} componentType={componentType} />
        )}

      {canBePurchased && !isGiftProduct && (
        <AddToCart
          product={product}
          componentType={componentType}
          variantSelectedOptions={variantSelectedOptions}
          engravableText={engravableText}
          engravableFonts={engravableFont}
          searchObject={searchObject}
          prices={prices}
          additionalProducts={optionalProducts?.map((p) => ({
            product: p,
            prices: p.prices,
          }))}
          addProductCallback={() => {
            if (optionalProducts.length > 0) {
              setOptionalProducts([]);
            }
          }}
        />
      )}

      {showShippingInfo && selectedVariant?.availableForSale && (
        <ProductShipping
          country={countryCode}
          isEngravable={isEngravable}
          tags={product.tags}
          productHandle={handle}
          selectedVariant={selectedVariant}
          preOrder={product.preOrder}
          isNumberCharmProduct={isNumberCharmProduct}
          componentType={componentType}
        />
      )}
    </div>
  );
}

ProductSummary.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string,
    shopifyId: PropTypes.string,
    handle: PropTypes.string,
    hts: PropTypes.string,
    title: PropTypes.string,
    productType: PropTypes.string,
    variants: PropTypes.arrayOf(
      PropTypes.shape({ sku: PropTypes.string, title: PropTypes.string })
    ),
    tags: PropTypes.arrayOf(PropTypes.string),
    engravable: PropTypes.shape({
      isEngravable: PropTypes.bool,
      fonts: PropTypes.arrayOf(PropTypes.string),
      numberOfCharacters: PropTypes.number,
    }),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        values: PropTypes.arrayOf(PropTypes.string),
      })
    ),
    productAlternatives: PropTypes.shape({
      alternatives: PropTypes.arrayOf(
        PropTypes.shape({
          attributes: PropTypes.arrayOf(PropTypes.string).isRequired,
          handle: PropTypes.string.isRequired,
        })
      ),
    }),
    isPreOrder: PropTypes.bool,
    optionalProducts: PropTypes.shape({
      optionalProductsTitle: PropTypes.string,
      optionalProductsCollection: PropTypes.shape({
        handle: PropTypes.string,
      }),
      optionalProductsType: PropTypes.string,
      optionalProductsIcon: PropTypes.shape({
        gatsbyImageData: PropTypes.shape({}),
      }),
    }),
  }),
  preOrder: PropTypes.bool,
  countryCode: PropTypes.string,
  selectedVariant: PropTypes.shape({
    [PropTypes.string]: PropTypes.string,
    availableForSale: PropTypes.bool,
  }),
  variantSelectedOptions: PropTypes.object,
  componentType: PropTypes.string,
  onReviewsClick: PropTypes.func,
  onVariantOptionChange: PropTypes.func,
  prices: PropTypes.shape({
    fullPrice: PropTypes.string,
    finalPrice: PropTypes.string,
    discountGiven: PropTypes.string,
  }),
  pricesLoading: PropTypes.bool,
  optionalProducts: PropTypes.array,
  setOptionalProducts: PropTypes.func,
};

export default withALErrorBoundary({
  name: "ProductSummary",
  priority: "P2",
})(ProductSummary);
