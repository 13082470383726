// extracted by mini-css-extract-plugin
export var awesome = "style-module--awesome--40be5";
export var bold = "style-module--bold--58e5b";
export var center = "style-module--center--8a1fb";
export var containerMargin = "style-module--containerMargin--25748";
export var contentPadding = "style-module--contentPadding--1254a";
export var giftTitle = "style-module--giftTitle--b291c";
export var giftWithPurchaseInfoContainerContent = "style-module--giftWithPurchaseInfoContainerContent--4b45a";
export var giftWithPurchaseInfoContainerContentSubtitle = "style-module--giftWithPurchaseInfoContainerContentSubtitle--1678f";
export var giftWithPurchaseInfoContainerContentTitle = "style-module--giftWithPurchaseInfoContainerContentTitle--7a181";
export var h1 = "style-module--h1--d0ade";
export var h1Bold = "style-module--h1--bold--06ad8";
export var h1Uc = "style-module--h1--uc--c44ef";
export var h2 = "style-module--h2--9f333";
export var h2Bold = "style-module--h2--bold--a3df6";
export var h2Uc = "style-module--h2--uc--ee148";
export var h3 = "style-module--h3--7b7fa";
export var h3Bold = "style-module--h3--bold--31fec";
export var h3Uc = "style-module--h3--uc--54d4a";
export var h4 = "style-module--h4--8c480";
export var h4Bold = "style-module--h4--bold--6cefb";
export var h4Uc = "style-module--h4--uc--b5a1c";
export var linkUnderline = "style-module--link--underline--f3be7";
export var microtext = "style-module--microtext--6c493";
export var microtextBold = "style-module--microtext--bold--af037";
export var microtextUc = "style-module--microtext--uc--f9d12";
export var nanotext = "style-module--nanotext--4ed61";
export var strikethrough = "style-module--strikethrough--72d7f";
export var strikethroughBold = "style-module--strikethrough--bold--2673e";
export var strikethroughLg = "style-module--strikethrough--lg--8b409";
export var strikethroughSm = "style-module--strikethrough--sm--7252d";
export var subtext = "style-module--subtext--d93d5";
export var subtextBold = "style-module--subtext--bold--be0a6";
export var subtextUc = "style-module--subtext--uc--6b3b8";
export var text = "style-module--text--e8a90";
export var textBold = "style-module--text--bold--23a01";
export var textUc = "style-module--text--uc--67478";
export var titleL = "style-module--title-l--4a1f7";
export var titleM = "style-module--title-m--b683d";
export var titleS = "style-module--title-s--495ad";
export var titleXl = "style-module--title-xl--e1feb";
export var titleXs = "style-module--title-xs--9004d";
export var titleXxs = "style-module--title-xxs--1c29b";
export var uc = "style-module--uc--78b1f";
export var underline = "style-module--underline--5b982";