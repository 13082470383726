import PropTypes from "prop-types";

import { resizeShopifyImage } from "../../../context/helpers";
import { useCurrentFormatCurrency, usePriceInfo } from "../../../hooks/usePrices";

import CheckboxFilled from "../../icons/svgs/checkbox-filled.svg";

import * as Styles from "./freeGiftProduct/styles.module.scss";

function FreeGiftProduct({ product, selectedGift, setSelectedGift }) {
  const [{ data: prices }] = usePriceInfo({
    handles: [product.handle],
    tags: { [product.handle]: product.shopify.tags },
  });
  const formatWithCurrency = useCurrentFormatCurrency();

  return (
    <div key={product.handle} className={Styles.freeGiftProduct}>
      <button
        className={Styles.freeGiftProduct__image}
        onClick={() => setSelectedGift(product.handle)}
        type="button"
      >
        <img
          src={resizeShopifyImage(product.shopify.images[0].url, "x400")}
          alt={product.shopify.images[0].altText}
        />
        <span
          className={`${Styles.freeGiftProduct__checkbox} ${
            selectedGift !== product.handle ? Styles.freeGiftProduct__checkbox_unselected : ""
          }`}
        >
          {selectedGift === product.handle ? <CheckboxFilled /> : <span>&nbsp;</span>}
        </span>
      </button>

      <div className={Styles.freeGiftProduct__info}>
        <div className="subtext subtext--bold subtext--uc">
          {product.shopify.title.split(" - ")[1]}
        </div>
        <div className={Styles.freeGiftProduct__price}>
          <span className="subtext subtext--bold subtext--uc">FREE</span>
          <span className="subtext strikethrough">{formatWithCurrency(prices?.fullPrice)}</span>
        </div>
      </div>
    </div>
  );
}

FreeGiftProduct.propTypes = {
  product: PropTypes.object.isRequired,
  selectedGift: PropTypes.string.isRequired,
  setSelectedGift: PropTypes.func.isRequired,
};
export default FreeGiftProduct;
