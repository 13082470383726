// extracted by mini-css-extract-plugin
export var awesome = "styles-module--awesome--ba689";
export var bold = "styles-module--bold--5fab4";
export var center = "styles-module--center--1eb9e";
export var freeGiftBanner = "styles-module--free-gift-banner--8c711";
export var freeGiftBanner__content = "styles-module--free-gift-banner__content--3505f";
export var freeGiftBanner__content_button = "styles-module--free-gift-banner__content_button--eb465";
export var freeGiftBanner__image = "styles-module--free-gift-banner__image--8618f";
export var h1 = "styles-module--h1--c911b";
export var h1Bold = "styles-module--h1--bold--e6116";
export var h1Uc = "styles-module--h1--uc--52612";
export var h2 = "styles-module--h2--2d9d9";
export var h2Bold = "styles-module--h2--bold--ffaf8";
export var h2Uc = "styles-module--h2--uc--eb477";
export var h3 = "styles-module--h3--5dc66";
export var h3Bold = "styles-module--h3--bold--28335";
export var h3Uc = "styles-module--h3--uc--7499d";
export var h4 = "styles-module--h4--f5cda";
export var h4Bold = "styles-module--h4--bold--37215";
export var h4Uc = "styles-module--h4--uc--7a9c4";
export var linkUnderline = "styles-module--link--underline--b6d91";
export var microtext = "styles-module--microtext--1552e";
export var microtextBold = "styles-module--microtext--bold--7b92a";
export var microtextUc = "styles-module--microtext--uc--236d2";
export var nanotext = "styles-module--nanotext--f1d97";
export var strikethrough = "styles-module--strikethrough--55474";
export var strikethroughBold = "styles-module--strikethrough--bold--41937";
export var strikethroughLg = "styles-module--strikethrough--lg--b0f2f";
export var strikethroughSm = "styles-module--strikethrough--sm--58c37";
export var subtext = "styles-module--subtext--c751c";
export var subtextBold = "styles-module--subtext--bold--aa5b1";
export var subtextUc = "styles-module--subtext--uc--de1c8";
export var text = "styles-module--text--2546c";
export var textBold = "styles-module--text--bold--1ee74";
export var textUc = "styles-module--text--uc--8bfc7";
export var titleL = "styles-module--title-l--22781";
export var titleM = "styles-module--title-m--d0954";
export var titleS = "styles-module--title-s--af323";
export var titleXl = "styles-module--title-xl--decfd";
export var titleXs = "styles-module--title-xs--8a056";
export var titleXxs = "styles-module--title-xxs--ffb11";
export var uc = "styles-module--uc--d72b4";
export var underline = "styles-module--underline--57546";