import { useState, createContext, useCallback, useMemo } from "react";
import { PropTypes } from "prop-types";

import { isBrowser, getCookie, createCookie, getAllUrlParams } from "./helpers";
import { alReportError } from "../helpers/ErrorBoundary/ALErrorBoundary";
import { useCurrentCountryCode } from "../hooks/usePrices";
import { useProductData2 } from "./products";

import DISCOUNT_TYPES from "../constants/DiscountTypes";
import WHITELIST from "../constants/WhitelistedDiscount";

export const defaultDiscountContext = {
  discountInfo: null,
  localizedDiscount: [],
  setFinalDiscount: () => {},
};

export const DiscountContext = createContext(defaultDiscountContext);

function useLocalizedDiscount(countryCode, discount) {
  return useMemo(() => {
    if (!countryCode) {
      return { localizedDiscount: [] };
    }

    const result = {
      localizedDiscount: discount.edges
        .filter((d) =>
          d.node.countries.find(
            (c) => c.code === countryCode || (countryCode !== "US" && c.code === "RoW")
          )
        )
        .map(({ node }) => node),
    };
    return result;
  }, [countryCode, discount]);
}

function DiscountProvider({ discount, children }) {
  const [store, updateStore] = useState(defaultDiscountContext);
  const countryCode = useCurrentCountryCode();
  const { localizedDiscount } = useLocalizedDiscount(countryCode, discount);

  const discountLocal = useMemo(() => {
    if (!isBrowser) {
      return "empty";
    }

    try {
      // Init discount local from cookies
      let initDiscountLocal = "empty";

      const uSource = getAllUrlParams()?.utm_source;
      if (uSource && WHITELIST.sourceAccepted.includes(uSource)) {
        // Extract discount info from url
        const uDiscountCode = getAllUrlParams()?.discount_code;
        let uDiscountAmount = getAllUrlParams()?.discount_a;
        const uDiscountType =
          getAllUrlParams()?.discount_t === "percentage"
            ? DISCOUNT_TYPES.SITEWIDE_PERCENT
            : getAllUrlParams()?.discount_t;

        if (uDiscountCode === "GET20") {
          uDiscountAmount = 25;
        }
        if (uDiscountCode && uDiscountType && uDiscountAmount) {
          // Store new discount to cookies
          const days = 1 / 24; // 1 / 24 days (1h) before Cookie expire
          createCookie("dSource", uSource, days);
          createCookie("dAmount", uDiscountAmount, days);
          createCookie("dCode", uDiscountCode, days);
          createCookie("dType", uDiscountType, days);

          // Set discount from urls params
          initDiscountLocal = {
            dSource: uSource,
            dAmount: uDiscountAmount,
            dCode: uDiscountCode,
            dType: uDiscountType,
            isFromParams: true,
            matchType: "exclude",
            matchTags: ["Final Sale", "EXCLUSION"],
          };
        }
      }

      return initDiscountLocal;
    } catch (e) {
      alReportError(e);
      return "empty";
    }
  }, []);

  let finalDiscounts = useMemo(() => {
    let finalDiscounts = [];

    if (localizedDiscount !== null) {
      finalDiscounts = localizedDiscount.map((discount) => {
        const finalDiscount = {
          isMaster: discount?.isMaster ?? false,
        };
        const dSource = getCookie("dSource");
        const dCode = getCookie("dCode");

        if (WHITELIST.codes.includes(dCode) || WHITELIST.sources.includes(dSource)) {
          finalDiscount.type = getCookie("dType");
          finalDiscount.value = parseInt(getCookie("dAmount"), 10);
          // finalDiscount.hasCrossedPrice = getCookie("dType") === DISCOUNT_TYPES.SITEWIDE_PERCENT;
          finalDiscount.code = getCookie("dCode");
          finalDiscount.isFromParams = true;
          finalDiscount.matchType = "exclude";
          finalDiscount.matchTags = ["Final Sale", "EXCLUSION"];
        }
        if (dCode === "GET20") {
          finalDiscount.value = 25;
        }

        if (
          discount &&
          (discountLocal == null || discountLocal === "empty" || discount?.isMaster === true)
        ) {
          finalDiscount.type = discount.type;
          finalDiscount.minimumPurchaseAmount = discount.minimumPurchaseAmount;
          finalDiscount.value = discount.percentageValue;
          finalDiscount.code = discount.discountCode;

          if (finalDiscount.type === DISCOUNT_TYPES.QUANTITY_PERCENT) {
            const compareNumbers = (a, b) => parseFloat(a) - parseFloat(b);
            discount.thresholdConditionQuantity.sort(compareNumbers);
            discount.thresholdResultPercentage.sort(compareNumbers);
            finalDiscount.discounts = discount.thresholdConditionQuantity.map((q, idx) => [
              parseInt(q, 10),
              parseFloat(discount.thresholdResultPercentage[idx]),
            ]);
            finalDiscount.thresholdConditionQuantity = discount.thresholdConditionQuantity;
            finalDiscount.thresholdResultPercentage = discount.thresholdResultPercentage;
          }
          if (
            discount?.minimumPurchaseAmount &&
            discount?.giftProductHandle &&
            discount.giftProductHandle !== "null"
          ) {
            finalDiscount.freegift = {
              minAmount: discount.minimumPurchaseAmount,
              minEligibleQuantity:
                typeof discount.thresholdConditionQuantity?.[0] === "string"
                  ? Number(discount.thresholdConditionQuantity?.[0])
                  : null,
              products:
                discount.fields?.giftProductsDataRaw &&
                JSON.parse(discount.fields.giftProductsDataRaw),
            };
          } else {
            finalDiscount.freegift = null;
          }
          finalDiscount.matchType = discount?.matchType;
          finalDiscount.matchTags = discount?.matchTags || [];
          finalDiscount.globalProductText = discount?.globalProductText;
          finalDiscount.colorInformation = discount?.colorInformation;
          finalDiscount.isLuxe = discount?.isLuxe;
          finalDiscount.hasCrossedPrice = !!discount?.hasCrossedPrice; // false: Currently not existing in CMS
        } else if (
          discountLocal != null &&
          discountLocal !== "empty" &&
          discount?.isMaster === false
        ) {
          finalDiscount.type = getCookie("dType");
          finalDiscount.value = parseInt(getCookie("dAmount"), 10);
          finalDiscount.hasCrossedPrice = getCookie("dType") === DISCOUNT_TYPES.SITEWIDE_PERCENT;
          finalDiscount.code = getCookie("dCode");
          finalDiscount.isFromParams = true;
          finalDiscount.exclusionTags = ["Final Sale", "EXCLUSION"];
        }
        return finalDiscount;
      });
    } else {
      finalDiscounts = ["empty"];
    }

    // Keep for a while, debug & report against discount usage
    if (isBrowser && window.exponea && finalDiscounts[0]?.code) {
      const debugDiscountData = {
        code: finalDiscounts[0]?.code,
        type: finalDiscounts[0]?.type,
        value: finalDiscounts[0]?.value,
        cookie_code: "",
        cookie_value: "",
        cookie_source: "",
        cookie_type: "",
      };
      if (discountLocal !== "empty") {
        debugDiscountData.cookie_code = discountLocal?.dCode;
        debugDiscountData.cookie_value = discountLocal?.dAmount;
        debugDiscountData.cookie_source = discountLocal?.dSource;
        debugDiscountData.cookie_type = discountLocal?.dType;
      }
      window.exponea.track("set_discount", debugDiscountData);
    }

    return finalDiscounts;
  }, [discountLocal, localizedDiscount]);

  // Upgrade finalDiscounts with product data for Gift Product
  const allGiftHandles = useMemo(
    () => finalDiscounts.map(({ freegift }) => freegift?.product?.handle).filter((h) => h),
    [finalDiscounts]
  );
  const allGiftProductData = useProductData2(allGiftHandles, "long", countryCode);
  finalDiscounts = useMemo(() => {
    if (allGiftProductData.status !== "success") {
      return finalDiscounts;
    }
    return finalDiscounts.map((discount) => {
      if (!discount.freegift) {
        return discount;
      }
      const giftProduct = allGiftProductData.data.find(
        (p) => p.handle === discount.freegift.product.handle
      );
      return {
        ...discount,
        freegift: {
          ...discount.freegift,
          product: giftProduct,
        },
      };
    });
  }, [finalDiscounts, allGiftProductData]);

  const setFinalDiscount = useCallback(
    (update) => {
      updateStore((latestStore) => ({
        ...latestStore,
        finalDiscounts: [{ ...finalDiscounts[0], ...update }],
      }));
    },
    [finalDiscounts]
  );

  const providerData = useMemo(
    () => ({
      ...store,
      setFinalDiscount,
      localizedDiscount,
      discountLocal,
      discountInfo: store.finalDiscounts || finalDiscounts,
    }),
    [store, setFinalDiscount, localizedDiscount, discountLocal, finalDiscounts]
  );

  return <DiscountContext.Provider value={providerData}>{children}</DiscountContext.Provider>;
}

DiscountProvider.propTypes = {
  discount: PropTypes.shape({
    edges: PropTypes.arrayOf(
      PropTypes.shape({
        node_locale: PropTypes.string,
        title: PropTypes.string,
        type: PropTypes.string,
        percentageValue: PropTypes.number,
        isMaster: PropTypes.bool,
        discountCode: PropTypes.string,
        minimumPurchaseAmount: PropTypes.number,
        countries: PropTypes.arrayOf(
          PropTypes.shape({
            code: PropTypes.string,
          })
        ),
        giftProductHandle: PropTypes.string,
        thresholdConditionQuantity: PropTypes.arrayOf(PropTypes.string),
        thresholdResultPercentage: PropTypes.arrayOf(PropTypes.string),
        matchType: PropTypes.oneOf(["include", "exclude"]),
        matchTags: PropTypes.arrayOf(PropTypes.string),
      })
    ).isRequired,
  }),
  children: PropTypes.node,
};

export default DiscountProvider;
