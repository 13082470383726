import { useState } from "react";
import PropTypes from "prop-types";
import { withALErrorBoundary } from "../../../helpers/ErrorBoundary/ALErrorBoundary";
import { resizeShopifyImage } from "../../../context/helpers";
import useDeviceDetect from "../../../hooks/useDeviceDetect";
import { useLocalizedSentenceDict } from "../../../hooks/useSentenceDict";
import { useCurrentCountryCode } from "../../../hooks/usePrices";
import { ALButton, ALSkeleton } from "../../ALComponents";
import Gallery from "../../ProductPage/Gallery";
import { ComponentType } from "../../../constants/ComponentType";
import { Country } from "../../../constants/Country";

import "./productImage/productImage.scss";

function ProductImage({
  shopifyProduct,
  quickAddToCart,
  componentType,
  isEngravable,
  handleQuickViewClick,
  isPurchaseRestricted,
  isGiftProduct,
}) {
  const dict = useLocalizedSentenceDict();
  const { isMobile } = useDeviceDetect();
  const countryCode = useCurrentCountryCode();
  const [isImageLoaded, setIsImageLoaded] = useState(null);

  // not using useResponsiveWithHydrationFix on purpose because
  // we don't want any first mobile render to cause mobile image loading on desktop
  const cpImages = isMobile ? (
    <div className="mobile_image_slider_wrapper">
      <Gallery
        images={[shopifyProduct.images[0], shopifyProduct.images?.[1]]}
        componentType={ComponentType.CP}
        className="mobile_image_slider"
      />
    </div>
  ) : (
    <div className="product_collection_wrapper">
      <img
        onLoad={() => setIsImageLoaded(true)}
        className="product_collection_image"
        style={!isImageLoaded ? { position: "absolute" } : null}
        src={resizeShopifyImage(shopifyProduct.images[0].url, "600X600")}
        alt={shopifyProduct.images[0].altText}
      />
    </div>
  );

  const showQuickAdd =
    !isMobile &&
    componentType !== ComponentType.BYOB &&
    shopifyProduct.variants.length === 1 &&
    shopifyProduct.variants[0].availableForSale &&
    !isEngravable &&
    !isPurchaseRestricted &&
    ((shopifyProduct.variants[0].inventoryPolicy === "DENY" && countryCode !== Country.US) ||
      countryCode === Country.US) &&
    !isGiftProduct;

  const showQuickView =
    componentType === ComponentType.CP ||
    componentType === ComponentType.SEARCH ||
    componentType === ComponentType.PDP_ADS;

  return (
    <>
      {shopifyProduct?.images?.length > 0 && (
        <>
          {!isImageLoaded && (
            <div className="product_collection_image_loading">
              <ALSkeleton position="absolute" />
            </div>
          )}

          {componentType === ComponentType.CP || componentType === ComponentType.PDP_ADS ? (
            cpImages
          ) : (
            <img
              onLoad={() => setIsImageLoaded(true)}
              className="product_collection_image"
              style={!isImageLoaded ? { position: "absolute" } : null}
              src={resizeShopifyImage(shopifyProduct.images[0].url, "600X600")}
              alt={shopifyProduct.images[0].altText}
            />
          )}
        </>
      )}

      {!isMobile && shopifyProduct?.images?.length > 1 && (
        <img
          className="product_collection_image_hover"
          src={resizeShopifyImage(shopifyProduct.images[1].url, "600X600")}
          alt={shopifyProduct.images[1].altText}
        />
      )}

      <div className="product_collection_buttons">
        {showQuickAdd && (
          <div className="product_collection_quickadd">
            <ALButton
              fullWidth
              onClick={(e) => {
                e.preventDefault();
                quickAddToCart();
              }}
              variant="primary"
            >
              {dict.get("QUICK ADD")}
            </ALButton>
          </div>
        )}

        {showQuickView && (
          <div className="product_collection_quickview">
            <ALButton
              variant="secondary"
              fullWidth
              onClick={(e) => {
                e.preventDefault();
                handleQuickViewClick();
              }}
            >
              {dict.get("QUICK VIEW")}
            </ALButton>
          </div>
        )}
      </div>
    </>
  );
}

ProductImage.propTypes = {
  shopifyProduct: PropTypes.object,
  quickAddToCart: PropTypes.func,
  componentType: PropTypes.string,
  isEngravable: PropTypes.bool,
  handleQuickViewClick: PropTypes.func,
  isPurchaseRestricted: PropTypes.bool,
  isGiftProduct: PropTypes.bool,
};

export default withALErrorBoundary({
  name: "ProductImage",
  priority: "P2",
})(ProductImage);
