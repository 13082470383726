import { useEffect, useCallback, useState, useRef } from "react";
import { StaticQuery, graphql } from "gatsby";
import { DidomiSDK } from "@didomi/react";
import { QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Helmet } from "react-helmet";
import Header from "../components/Header";
import TangibleeListener from "../components/TangibleeListener";
import AnnouncementBar from "../components/AnnouncementBar";
import AnnouncementBarProvider from "../context/AnnouncementBarProvider";
import DiscountProvider from "../context/DiscountProvider";
import Provider from "../context/provider";
import AuthProvider from "../context/AuthProvider";
import WishlistProvider from "../context/wishlistProvider";
import ShippingDelayProvider from "../context/ShippingDelayProvider";
import { HiddenElementsContextProvider } from "../context/HiddenElements";
import {
  getAllUrlParams,
  createRootDomainCookie,
  isBrowser,
  getCookie,
  createCookie,
} from "../context/helpers";
import { BYOBProvider } from "../context/BYOBundle";
import LoopReturnProvider from "../context/LoopReturnProvider";
import GeoProvider from "../context/GeoProvider";
import CartProvider from "../context/CartProvider";
import IntelligemsProvider from "../context/IntelligemsProvider";
import LuxeProvider from "../context/LuxeProvider";
import ExponeaProvider from "../context/ExponeaProvider";

import useResponsiveWithHydrationFix from "../hooks/useResponsiveWithHydrationFix";
import IntelligemsHOC from "../hoc/IntelligemsHOC";
import QuickAccess from "../components/QuickAccess";
import FloatingChat from "../components/FloatingChat";
import FloatingPromotion from "../components/FloatingPromotion";
import CountryConfirmationPopup from "../components/CountryConfirmationPopup";
import QuickViewPopup from "../components/QuickViewPopup";
import SubscriptionPopUp from "../components/SubscriptionPopUp";
import OptionalProductsDrawer from "../components/ProductPage/productSummary/optionalProducts/OptionalProductsDrawer";
import Footer from "../components/Footer/footer";
import MembershipCreditFrame from "../components/luxe/MembershipCreditFrame";

import { alReportError, withALErrorBoundary } from "../helpers/ErrorBoundary/ALErrorBoundary";
import { getGlobalEData } from "../hooks/useLocalizedCartInfo";

import "./layout.scss";
import "./fonts.scss";
import "./font_guideline.scss";
import "./components.scss";
import "./spacing.scss";
import "./typography.scss";
import "./orders.scss";

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => {
      if (typeof query.meta?.sendReport === "function") {
        query.meta.sendReport(error, { name: query.meta?.name, priority: query.meta?.priority });
      } else {
        alReportError(error, query.meta?.name, query.meta?.priority);
      }
    },
  }),
});

function Layout({ children, location }) {
  const [searchVisible, setSearchVisible] = useState(false);
  const [isProductPage, updateIsProductPage] = useState(location.pathname.includes("/products/"));
  const [isLpPlpPage, updateIsLpPlpPage] = useState(location.pathname.includes("/p/"));
  const [isBlog, updateIsBlog] = useState(location.pathname.includes("/blog/"));
  const [isRewardsPage, updateIsRewardPage] = useState(
    /^\/pages\/wishlist|rewards|order-history|account|ana-luisa-luxe/.test(location.pathname)
  );
  const [displayQuickAccess, setDisplayQuickAccess] = useState(true);
  const [displayAB, setDisplayAB] = useState(!location.pathname.includes("/lp/"));
  const [displayNavigation, setDisplayNavigation] = useState(!location.pathname.includes("/lp/"));
  const [displayCredits, setDisplayCredits] = useState(false);
  const [optionalProductsData, setOptionalProductsData] = useState(null); // can be Null or propTypes of OptionalProductsDrawer
  const headerRef = useRef(null);
  const displayFooter = !location.pathname.includes("/lp/");

  const [luxeInfoPopupOpen, setLuxeInfoPopupOpen] = useState(
    location.search.includes("luxe_details=true")
  );

  useEffect(() => {
    if (location.pathname.includes("/products/")) {
      updateIsProductPage(true);
    } else {
      updateIsProductPage(false);
    }

    if (location.pathname.includes("/p/")) {
      updateIsLpPlpPage(true);
    } else {
      updateIsLpPlpPage(false);
    }

    if (/^\/pages\/wishlist|rewards|order-history|account|ana-luisa-luxe/.test(location.pathname)) {
      updateIsRewardPage(true);
    } else {
      updateIsRewardPage(false);
    }

    if (location.pathname.includes("/blog/")) {
      updateIsBlog(true);
    } else {
      updateIsBlog(false);
    }

    // Create GE replacement cookie when replacementExpire is in the URL params
    const queryParams = getAllUrlParams(location.href);
    const geData = getGlobalEData();
    if (queryParams.replacementExpire) {
      createRootDomainCookie(
        "GE_Replacement",
        encodeURIComponent(
          `{"glCountry": "${geData?.countryISO.toUpperCase()}", "glCurrency": "${geData?.currencyCode.toUpperCase()}"}`
        ),
        null,
        Number(queryParams.replacementExpire)
      );
    }

    setDisplayCredits(typeof queryParams.credit !== "undefined");

    // AL LUXE cookie creation when coming from Google ADS (for 1 day)
    const disableSubscriptionCookie = getCookie("subscription_disabled");
    if (queryParams.utm_source === "google") {
      if (!disableSubscriptionCookie) {
        createCookie("subscription_disabled", true, 1);
      }
    }

    // Make sure the top AB is displayed
    if (!location.pathname.includes("/collections/")) {
      setDisplayAB(true);
    }
  }, [location]);

  const setSearchPopupVisible = useCallback((visible) => {
    if (isBrowser) {
      document.body.style.overflow = visible ? "hidden" : "";
    }
    setSearchVisible(visible);
  }, []);

  useEffect(() => {
    if (isBrowser) {
      try {
        window.edgetag("init", {
          edgeURL: "https://opcqf.analuisa.com",
          disableConsentCheck: true,
        });
      } catch (error) {
        console.log("error in blotout init", error);
      }

      try {
        const exponeaEmail = isBrowser && window.exponea?.email_id;
        if (exponeaEmail) {
          window.edgetag("user", "email", exponeaEmail);
        }
      } catch (error) {
        console.error(error);
      }

      const header = document.getElementById("header");
      if (!header) {
        return;
      }
      const sticky = header.offsetTop;
      const scrollCallBack = window.addEventListener("scroll", () => {
        if (window.pageYOffset - header.offsetHeight / 2 > sticky) {
          if (!header.classList.contains("header_sticky")) {
            header.classList.add("header_sticky");
          }
        } else {
          header.classList.remove("header_sticky");
        }
      });

      return () => {
        window.removeEventListener("scroll", () => scrollCallBack);
      };
    }
  }, []);

  const quickAccessDiv = useResponsiveWithHydrationFix(
    displayQuickAccess && !isProductPage && !isRewardsPage && !isLpPlpPage && !isBlog && (
      <QuickAccess />
    ),
    null
  );

  let luxeData = [
    {
      key: "exison_plan_settings",
      namespace: "exison",
      storeName: "analuisaparis",
      type: "json_string",
      value:
        '{"status":"1","type":"1","product_name":"AL Luxe","product_price":"39.99","product_gid":7913700229319,"product_variant_id":43790876934343,"tier_name":"AL Luxe","plan_id":1807352007,"plan_name":"billed every 1 month","i_name":"month","i_count":"1","scripts_st_on":0,"otp_enabled":0,"dp":"1","dv":"5.00","dct":"1","dt":"percentage","dat":"all","ds":"0","wo":"0","wo_v":"0.00","wo_t":"percentage","wo_at":"all","wo_collection":[],"wo_product":[],"fs":"0","ft":"1","ft_price":"0.00","ft_type":"1","ft_duration":"3"}',
    },
  ];
  if (process.env.GATSBY_SHOPIFY1_STORE_NAME === "analuisa-staging") {
    luxeData = [
      {
        key: "exison_plan_settings",
        namespace: "exison",
        storeName: "analuisa-staging",
        type: "json_string",
        value:
          '{"status":"1","type":"1","product_name":"AL+","product_price":"2.99","product_gid":8314950320386,"product_variant_id":44196237967618,"tier_name":"VIP","plan_id":2429124866,"plan_name":"billed every 2 day","i_name":"day","i_count":"2","scripts_st_on":0,"otp_enabled":1,"dp":"1","dv":"10.00","dct":"1","dt":"percentage","dat":"all","ds":"0","wo":"0","wo_v":"0.00","wo_t":"percentage","wo_at":"all","wo_collection":[],"wo_product":[],"fs":"0","ft":"1","ft_price":"0.00","ft_type":"1","ft_duration":"3"}',
      },
    ];
  }

  const pinterestTag = [
    {
      name: "p:domain_verify",
      content: "15fa34f13f08d57a4a9343ee44452543",
    },
  ];
  return (
    <StaticQuery
      query={graphql`
        query {
          recommendationExponea: allContentfulExponeaRecommendationContent(
            filter: { node_locale: { eq: "en-US" } }
          ) {
            edges {
              node {
                node_locale
                recommendationId
                type
                country {
                  code
                  country
                }
              }
            }
          }
          discount: allContentfulSale(filter: { node_locale: { eq: "en-US" } }) {
            edges {
              node {
                node_locale
                title
                countries {
                  code
                }
                isLuxe
                isMaster
                discountCode
                matchType
                matchTags
                type
                minimumPurchaseAmount
                percentageValue
                giftProductHandle
                giftProductHandles
                fields {
                  giftProductsDataRaw
                }
                thresholdConditionQuantity
                thresholdResultPercentage
                globalProductText {
                  text
                  arrivalDate
                }
                colorInformation {
                  discountCode
                  hpButtonBgColorDesktop
                  hpBgColorDesktop
                  hpButtonHoverBgColorDesktop
                  hpTextColorDesktop
                  hpBgColorMobile
                  hpButtonBgColorMobile
                  hpButtonHoverBgColorMobile
                  hpTextColorMobile
                  tierBgColorGuest
                  tierTextColorGuest
                  tierInverseTextColorGuest
                  tierBorderColorGuest
                  buttonBackgroundColorGuest
                  buttonTextColorGuest
                  tierBgColorLuxe
                  tierTextColorLuxe
                  tierInverseTextColorLuxe
                  tierBorderColorLuxe
                  buttonBackgroundColorLuxe
                  buttonTextColorLuxe
                  tierBgColorLuxeReached
                  tierTextColorLuxeReached
                }
              }
            }
          }
          luxeEditBlock: contentfulLuxeLuxeEditBlock {
            title
            buttonText
            image {
              desktopImage {
                gatsbyImageData
              }
              mobileImage {
                gatsbyImageData
              }
            }
            collection {
              handle
            }
          }
          # subscription: allShopifyMetafields(filter: { namespace: { eq: "exison" } }) {
          #   edges {
          #     node {
          #       namespace
          #       storeName
          #       type
          #       key
          #       value
          #     }
          #   }
          # }
        }
      `}
      render={(data) => (
        <GeoProvider>
          <Helmet meta={pinterestTag} />

          <ExponeaProvider>
            <HiddenElementsContextProvider>
              <FloatingChat />
              <FloatingPromotion />
              <DidomiSDK
                apiKey="6548bad1-0168-47ce-ac9b-870e1cd36b26"
                noticeId="TthXt7YQ"
                iabVersion={2}
                gdprAppliesGlobally={false}
              />
              <IntelligemsProvider>
                <QueryClientProvider client={queryClient}>
                  <DiscountProvider discount={data?.discount ?? null}>
                    <Provider
                      recommendationExponea={
                        data && data.recommendationExponea ? data.recommendationExponea : null
                      }
                      setDisplayQuickAccess={setDisplayQuickAccess}
                      setDisplayAB={setDisplayAB}
                      displayAB={displayAB}
                      setDisplayNavigation={setDisplayNavigation}
                      displayNavigation={displayNavigation}
                      setOptionalProductsData={setOptionalProductsData}
                    >
                      <CartProvider>
                        <AuthProvider>
                          <LoopReturnProvider>
                            <LuxeProvider subscription={luxeData} luxeEdit={data?.luxeEditBlock}>
                              <BYOBProvider>
                                <WishlistProvider>
                                  <AnnouncementBarProvider>
                                    <ShippingDelayProvider>
                                      <IntelligemsHOC>
                                        <div style={{ position: "relative" }}>
                                          <AnnouncementBar hidden={!displayAB} />
                                          <TangibleeListener />
                                          <Header
                                            ref={headerRef}
                                            searchPopupVisible={searchVisible}
                                            setSearchPopupVisible={setSearchPopupVisible}
                                            displayNavigation={displayNavigation}
                                          />
                                          {quickAccessDiv}
                                          <div style={{ position: "relative" }}>{children}</div>
                                          {displayFooter && <Footer />}
                                          {displayCredits && (
                                            <MembershipCreditFrame
                                              style={{
                                                position: "fixed",
                                                zIndex: "999999999999",
                                              }}
                                              setDisplayCredits={setDisplayCredits}
                                            />
                                          )}
                                          {isProductPage && (
                                            <OptionalProductsDrawer
                                              isVisible={!!optionalProductsData}
                                              {...(optionalProductsData || {})}
                                            />
                                          )}
                                          <CountryConfirmationPopup />
                                          <QuickViewPopup />
                                          <SubscriptionPopUp
                                            isOpen={luxeInfoPopupOpen}
                                            onClose={() => setLuxeInfoPopupOpen(false)}
                                          />
                                        </div>
                                      </IntelligemsHOC>
                                    </ShippingDelayProvider>
                                  </AnnouncementBarProvider>
                                </WishlistProvider>
                              </BYOBProvider>
                            </LuxeProvider>
                          </LoopReturnProvider>
                        </AuthProvider>
                      </CartProvider>
                    </Provider>
                  </DiscountProvider>
                </QueryClientProvider>
              </IntelligemsProvider>
            </HiddenElementsContextProvider>
          </ExponeaProvider>
        </GeoProvider>
      )}
    />
  );
}

export default withALErrorBoundary({
  name: "Layout",
  priority: "P1",
})(Layout);
