import PropTypes from "prop-types";
import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";

import * as Styles from "./giftWithPurchaseInfo/style.module.scss";

function GiftWithPurchaseInfo({ isGiftProduct }) {
  return (
    <div
      className={`${Styles.giftWithPurchaseInfoContainerContent} ${
        isGiftProduct ? Styles.containerMargin : ""
      }`}
    >
      <div
        className={`${Styles.giftWithPurchaseInfoContainerContentTitle} ${
          !isGiftProduct ? Styles.contentPadding : ""
        }`}
      >
        <p className={`${!isGiftProduct ? Styles.giftTitle : ""}`}>
          {isGiftProduct ? "Buy 2+ items," : "LIMITED STOCK"}
        </p>
      </div>
      <div
        className={`${Styles.giftWithPurchaseInfoContainerContentSubtitle} ${
          !isGiftProduct ? Styles.contentPadding : ""
        }`}
      >
        <p>{isGiftProduct ? "Get this one for FREE!" : "Buy 2+ items and choose a free gift"}</p>
      </div>
    </div>
  );
}

GiftWithPurchaseInfo.propTypes = {
  isGiftProduct: PropTypes.bool.isRequired,
};

export default withALErrorBoundary({
  name: "GiftWithPurchaseInfo",
  priority: "P1",
})(GiftWithPurchaseInfo);
