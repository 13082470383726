import PropTypes from "prop-types";
import { useEffect, useMemo, useState, memo } from "react";
import reactStringReplace from "react-string-replace";

import useData from "../../../hooks/useData";
import { useLocalizedSentenceDict } from "../../../hooks/useSentenceDict";
import useDeviceDetect from "../../../hooks/useDeviceDetect";
import { withALErrorBoundary } from "../../../helpers/ErrorBoundary/ALErrorBoundary";
import useSEOSentenceToLinkConversion from "../../../hooks/useSEOSentenceToLinkConversion";

import CompleteTheLook from "../../CompleteTheLook/CompleteTheLook";
import ProductUsp from "../ProductUsp";
import { ALLink, ALRadioNavigation } from "../../ALComponents";
import HowItWorks from "./productDetails/HowItWorks";
import ContentCare from "./productDetails/ContentCare";
import ContentQuality from "./productDetails/ContentQuality";
import ContentDetails from "./productDetails/ContentDetail";
import BestReviews from "./productDetails/BestReviews";

import { ComponentType } from "../../../constants/ComponentType";
import TAG from "../../../constants/Tag";

import "../product_details.scss";

const SOLID_GOLD_TAG = "Components:Solid Gold";

function ProductDetails({
  tags = [],
  productHandle,
  isBundle,
  countryCode,
  componentType,
  details,
  description,
  title,
  completeTheLookProducts = [],
  onScrollToReviews,
  bestReviews,
  productLinkToPDP,
  closeQuickView = () => {},
  expandableDetails = false,
  isPreOrder,
  isGiftProduct,
}) {
  const dict = useLocalizedSentenceDict();
  const { isMobile } = useDeviceDetect();

  const isSolidGold = tags.includes(SOLID_GOLD_TAG);
  // Solid Gold products have details tab open by default
  const [activeTab, setActiveTab] = useState(isSolidGold ? "details" : "");

  const bundleDetails = useData("bundle-details", { handle: productHandle }, isBundle) || [];

  const showProductCare =
    ComponentType.BYOB_PDP !== componentType && ComponentType.QUICKVIEW !== componentType;
  const showProductUSP =
    !(isMobile && componentType === ComponentType.QUICKVIEW) &&
    !(componentType === ComponentType.PDP_ADS);

  const descriptionWithSEO = useSEOSentenceToLinkConversion(description);
  const formattedDescription = useMemo(
    () => reactStringReplace(descriptionWithSEO),
    [descriptionWithSEO]
  );

  let hasDetails = true;

  if (details) {
    const nullDetails = Object.keys(details).map((e) => {
      if (Array.isArray(details[e]) && details[e][0] === "null") return true;
      if (typeof details[e] === "string" && details[e].trim() === "null") return true;
      return false;
    });
    if (nullDetails.every((e) => e === true)) {
      hasDetails = false;
    }
  }

  const navigationElements = useMemo(() => {
    const showHowItWorks = tags?.includes(TAG.MYSTERY);
    const showDetails = details && hasDetails;
    const showQuality = componentType !== ComponentType.BYOB_PDP;
    const showDescription = !!description;

    return [
      showHowItWorks && { key: "howitworks", content: dict.get("How it works") },
      showQuality && { key: "quality", content: dict.get("Quality") },
      showDetails && { key: "details", content: dict.get("Details") },
      showProductCare && { key: "care", content: dict.get("Care") },
      showDescription && { key: "description", content: dict.get("Description") },
    ].filter((e) => e);
  }, [tags, details, hasDetails, componentType, description, dict, showProductCare]);

  useEffect(() => {
    if (!expandableDetails && !activeTab) {
      setActiveTab(navigationElements[0].key);
    }
  }, [expandableDetails, navigationElements, activeTab]);

  const detailsList = isBundle ? bundleDetails : [{ productDetails: details }];

  return (
    <div className={`pdp_details_info pdp_details_info__${componentType?.toLowerCase()}`}>
      {showProductUSP && <ProductUsp countryCode={countryCode} isPreOrder={isPreOrder} />}

      {componentType !== ComponentType.QUICKVIEW && (
        <div className="pdp_details_wrapper">
          <ALRadioNavigation
            elements={navigationElements}
            onClick={setActiveTab}
            selectedKey={activeTab}
            isExpandable={expandableDetails}
          />
          <div
            className={`${
              componentType === ComponentType.PDP_ADS ? "auto-height" : ""
            } pdp_details_content mt-20`}
            data-testid="details-content"
          >
            <div
              className={activeTab === "howitworks" ? "" : "hidden"}
              data-testid="content-howitworks"
            >
              <HowItWorks />
            </div>
            <div className={activeTab === "quality" ? "" : "hidden"} data-testid="content-quality">
              <ContentQuality />
            </div>
            <div className={activeTab === "details" ? "" : "hidden"} data-testid="content-details">
              <ContentDetails detailsList={detailsList} componentType={componentType} />
            </div>
            <div className={activeTab === "care" ? "" : "hidden"} data-testid="content-care">
              <ContentCare />
            </div>
            <div
              className={activeTab === "description" ? "white-space" : "hidden"}
              data-testid="description-paragraph"
            >
              <p className={`subtext ${activeTab === "description" ? "" : "hidden"}`}>
                {formattedDescription}
              </p>
            </div>
          </div>
        </div>
      )}

      {componentType === ComponentType.QUICKVIEW && (
        <ALLink to={productLinkToPDP} className="link--underline" onClick={closeQuickView}>
          {isMobile ? dict.get("View full product details") : dict.get("View product details")}
        </ALLink>
      )}

      {!isGiftProduct && (
        <BestReviews reviews={bestReviews || []} scrollToReviews={onScrollToReviews} />
      )}

      {completeTheLookProducts?.length > 0 && (
        <CompleteTheLook
          products={completeTheLookProducts}
          productTitle={title}
          handle={productHandle}
        />
      )}
    </div>
  );
}

ProductDetails.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string),
  productHandle: PropTypes.string.isRequired,
  isBundle: PropTypes.bool,
  countryCode: PropTypes.string,
  details: PropTypes.shape({
    productDetails: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
      })
    ),
  }),
  componentType: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string,
  completeTheLookProducts: PropTypes.arrayOf(PropTypes.object),
  onScrollToReviews: PropTypes.func,
  productLinkToPDP: PropTypes.string,
  closeQuickView: PropTypes.func,
  expandableDetails: PropTypes.bool,
  isPreOrder: PropTypes.bool,
  isGiftProduct: PropTypes.bool,
};

export default memo(
  withALErrorBoundary({
    name: "ProductDetails",
    priority: "P3",
  })(ProductDetails)
);
