import { useState } from "react";
import PropTypes from "prop-types";
import SwiperCore, { Navigation, Pagination, A11y, Thumbs, FreeMode } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// eslint-disable-next-line import/no-unresolved
import { useIgTestGroup } from "@intelligems/headless/gatsby";

import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import { isBrowser, isCrawlerBot } from "../../context/helpers";
import useDeviceDetect from "../../hooks/useDeviceDetect";
import { ALVideo } from "../ALComponents";
import DesktopImage from "./gallery/DesktopImage";
import ThumbnailGallery from "./gallery/ThumbnailGallery";
import MobileGalleryPopup from "./gallery/MobileGalleryPopup";
import SocialProof from "../socialProof/SocialProof";
import ALShopifyImage from "../al_components/ALShopifyImage";
import EmergencyDaily from "./productSummary/EmergencyDaily";

import { ActionType } from "../../constants/ActionType";
import { ComponentType } from "../../constants/ComponentType";
import { Country } from "../../constants/Country";

import GiftIcon from "../icons/svgs/gift.svg";
import IconHeart from "../icons/svgs/heart.svg";

import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";

import "./gallery.scss";

SwiperCore.use([Navigation, Pagination, A11y, FreeMode, Thumbs]);

function Gallery({
  images,
  videoId,
  isUS,
  isGiftCard,
  available,
  isInWishlist,
  toggleWishlist,
  className = "",
  componentType,
  onDropHintClick,
  video = {},
  product = {},
  selectedVariant = {},
  countryCode = "",
  isGiftProduct = false,
}) {
  const { isMobile } = useDeviceDetect();

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [activeImage, setActiveImage] = useState(images?.length > 0 ? images[0] : null);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const showSwiper =
    isMobile ||
    componentType === ComponentType.BYOB ||
    componentType === ComponentType.QUICKVIEW ||
    componentType === ComponentType.BYOB_PDP;
  const showNavigation =
    componentType === ComponentType.QUICKVIEW ||
    componentType === ComponentType.BYOB ||
    componentType === ComponentType.BYOB_PDP ||
    componentType === ComponentType.PDP;
  const showEmergency = componentType === ComponentType.PDP;

  let showSocialProof = componentType === ComponentType.PDP;

  /**
   * Start [A/B Test] PDP - Remove social proofing/thumbnail
   */

  const abTest = useIgTestGroup("66deb182-425b-4b1e-abbc-03b6e11d84ea");

  if (abTest?.testGroup?.name === "Version_C") {
    showSocialProof = componentType === ComponentType.PDP && !isMobile;
  }

  const showThumbnailGallery =
    isMobile && componentType === ComponentType.PDP && abTest?.testGroup?.name === "Default";

  const emergencyBottom = abTest?.testGroup?.name === "Default" ? "5px" : "15px";

  const showPagination =
    abTest?.testGroup?.name === "Default" && componentType === ComponentType.PDP
      ? false
      : {
          clickable: true,
          modifierClass: "gallery_m_swiper_pagination",
          bulletClass: "gallery_m_swiper_bullet",
          bulletActiveClass: "gallery_m_swiper_bullet--active",
        };

  /**
   * End [A/B Test] PDP - Remove social proofing/thumbnail
   */

  const hasVideo = ((!!videoId && videoId !== "null") || (!!video && video.url)) && !isCrawlerBot();

  const openPopup = (img) => {
    // Make sure we don't trigger the popup on other components than PDP
    if (componentType === ComponentType.PDP) {
      setActiveImage(img);
      setIsPopupOpen(true);
    }
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const hasVariantAvailable =
    selectedVariant?.availableForSale === true && selectedVariant?.inventoryPolicy === "DENY";
  const shouldDisplayPreOrderFomo =
    product?.isPreOrder && countryCode === Country.US && !hasVariantAvailable;

  if (!images) {
    return null;
  }

  const DesktopVideo = hasVideo
    ? [
        <ALVideo
          key={videoId}
          videoId={video?.url ? video.url : videoId}
          videoUrl={video?.url || null}
          customClasses={{
            container: "pdp_image pdp_image_video_container",
            vimeo_container: "pdp_image_video",
            image_thumbnail: "pdp_image_video_thumbnail",
            skeleton_container: "pdp_sk_m",
            skeleton_image: "pdp_sk_image",
            video_container: "video_container",
            video: "video-vimeo",
          }}
        >
          {showEmergency && (
            <div
              style={{
                position: "absolute",
                width: "calc(100% - 40px)",
                bottom: "5px",
                left: "20px",
                zIndex: "2",
              }}
            >
              <EmergencyDaily
                productHandle={product.handle}
                productType={product.productType}
                shouldDisplayPreOrderFomo={shouldDisplayPreOrderFomo}
              />
            </div>
          )}
        </ALVideo>,
      ]
    : [];
  const DesktopImages = images.map((image, i) => (
    <DesktopImage key={image?.url} image={image}>
      {showEmergency && i === 0 && !hasVideo && (
        <div
          style={{
            position: "absolute",
            width: "calc(100% - 40px)",
            bottom: "5px",
            left: "20px",
            zIndex: "2",
          }}
        >
          <EmergencyDaily
            productHandle={product.handle}
            productType={product.productType}
            shouldDisplayPreOrderFomo={shouldDisplayPreOrderFomo}
          />
        </div>
      )}
    </DesktopImage>
  ));
  const SocialProofComponent = (
    <div className="ab_pdp_social_proof" key="social_proof">
      <SocialProof />
    </div>
  );

  const combinedComponents = [...DesktopVideo, ...DesktopImages];

  if (componentType === ComponentType.PDP) {
    combinedComponents.splice(2, 0, SocialProofComponent);
  }
  const AllMediaComponent = combinedComponents;

  return (
    <div className={`${isMobile ? "gallery_m" : "gallery"} ${className}`}>
      {!isUS && (isGiftCard || isGiftProduct)
        ? null
        : available &&
          !isGiftProduct &&
          (isInWishlist != null ? (
            isInWishlist ? (
              <button
                className="pdp_wishlist pdp_wishlist--remove er-wishlist"
                onClick={() => toggleWishlist(ActionType.REMOVE)}
                type="button"
                data-testid="wishlist-icon-full"
              >
                <IconHeart width="18" height="15" fill="#2D2927" stroke="#2D2927" />
              </button>
            ) : (
              <button
                className="pdp_wishlist pdp_wishlist--add er-wishlist"
                onClick={() => toggleWishlist(ActionType.ADD)}
                type="button"
                data-testid="wishlist-icon-empty"
              >
                <IconHeart width="18" height="15" stroke="#2D2927" />
              </button>
            )
          ) : null)}

      {componentType === ComponentType.PDP && !isGiftProduct && (
        <button
          type="button"
          className="pdp-drop-hint"
          onClick={onDropHintClick}
          data-testid="drop-hint-button"
        >
          <GiftIcon width="18" height="20" fill="#FFFFFF" stroke="#2D2927" />
        </button>
      )}

      {showSwiper ? (
        <>
          <Swiper
            spaceBetween={0}
            slidesPerView={1}
            loop
            onActiveIndexChange={(swiper) => {
              // On active index change, we need to remove the video from the index if present
              const removeCount = hasVideo ? 1 : 0;
              setActiveImage(images[Math.max(swiper.realIndex - removeCount, 0)]);
            }}
            pagination={showPagination}
            navigation={showNavigation}
            thumbs={{ swiper: thumbsSwiper }}
            className={`main_gallery main_gallery--${componentType.toLowerCase()}`}
          >
            {showEmergency && (
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  bottom: emergencyBottom,
                  zIndex: "2",
                }}
              >
                <EmergencyDaily
                  productHandle={product.handle}
                  productType={product.productType}
                  shouldDisplayPreOrderFomo={shouldDisplayPreOrderFomo}
                />
              </div>
            )}

            {hasVideo && (
              <SwiperSlide key={`product_video_${videoId}_pdp`}>
                <ALVideo
                  videoId={video?.url ? video.url : videoId}
                  videoUrl={video?.url || null}
                  customClasses={{
                    container: "pdp_image pdp_image_video_container",
                    vimeo_container: "pdp_image_video",
                    image_thumbnail: "pdp_image_video_thumbnail",
                    skeleton_container: "pdp_sk_m",
                    skeleton_image: "pdp_sk_image",
                    video_container: "video_container",
                    video: "video-vimeo",
                  }}
                />
              </SwiperSlide>
            )}

            {images.map((p) => {
              if (p.altText === null || !p.altText.includes("vimeo.com")) {
                return (
                  <SwiperSlide key={`product_image_${p.url}_pdp`} onClick={() => openPopup(p)}>
                    <ALShopifyImage
                      width={isBrowser && window?.innerWidth}
                      height={
                        componentType === ComponentType.PDP && isBrowser && window?.innerWidth
                      }
                      url={p.url}
                      alt={p.altText}
                    />
                  </SwiperSlide>
                );
              }
              return null;
            })}
          </Swiper>

          {showThumbnailGallery && (
            <ThumbnailGallery
              setThumbsSwiper={setThumbsSwiper}
              images={images}
              videoId={hasVideo ? videoId : null}
              videoUrl={hasVideo ? video?.url : null}
            />
          )}

          {showSocialProof && (
            <div className="ab_pdp_social_proof">
              <SocialProof />
            </div>
          )}
        </>
      ) : (
        <div className="pdp_media_container">{AllMediaComponent}</div>
      )}

      {isPopupOpen && (
        <MobileGalleryPopup
          images={images}
          activeImage={activeImage}
          setActiveImage={setActiveImage}
          closePopup={closePopup}
        />
      )}
    </div>
  );
}

Gallery.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      altText: PropTypes.string,
    })
  ),
  videoId: PropTypes.string,
  isUS: PropTypes.bool,
  isGiftCard: PropTypes.bool,
  available: PropTypes.bool,
  isInWishlist: PropTypes.bool,
  toggleWishlist: PropTypes.func,
  className: PropTypes.string,
  componentType: PropTypes.string,
  onDropHintClick: PropTypes.func,
};

export default withALErrorBoundary({
  name: "Gallery",
  priority: "P2",
})(Gallery);
