import { useContext, /* useMemo, */ useState } from "react";
import PropTypes from "prop-types";

import { applyIgPrices } from "../helpers/intelligems";
import { LuxeContext } from "../context/LuxeProvider";

import { withALErrorBoundary } from "../helpers/ErrorBoundary/ALErrorBoundary";
import { useLocalizedSentenceDict } from "../hooks/useSentenceDict";
import useDeviceDetect from "../hooks/useDeviceDetect";
import { getCookie } from "../context/helpers";
import { useCurrentFormatCurrency } from "../hooks/usePrices";
import useDiscountsInfo, { DISCOUNT_INFO_MODES } from "../hooks/useDiscountsInfo";
import useIsBannedProduct from "../hooks/useIsBannedProduct";
import useApplyLuxeDiscountToProductPrice from "../hooks/useApplyLuxeDiscountToProductPrice";

import { ALSkeleton } from "./ALComponents";
import { ComponentType } from "../constants/ComponentType";
import DISCOUNT_TYPES from "../constants/DiscountTypes";
import SubscriptionPopUp from "./SubscriptionPopUp";

import LuxeIcon from "./icons/svgs/luxe.svg";
import InfoIcon from "./icons/svgs/info.svg";
import GiftIcon from "./icons/svgs/gift.svg";

import * as Styles from "./priceContainer/styles.module.scss";

const LUXE_EXCLUDED_COMPONENTS = [
  ComponentType.PDP_ADS,
  ComponentType.CP_ADS,
  ComponentType.SIDE_CART_UPSELL,
  ComponentType.FLOATING_ATC_ADS,
  ComponentType.PDP_QUICK_UPSELL,
];

function PriceContainer({
  productId,
  selectedVariant,
  prices,
  pricesLoading,
  componentType,
  tags = [],
  optionalProductsTotalPrice = 0,
}) {
  const discountsInfo = useDiscountsInfo();
  const luxeDiscountsInfo = useDiscountsInfo(DISCOUNT_INFO_MODES.LUXE_ONLY);
  const giftDiscount =
    (discountsInfo || []).find((s) => s.type === DISCOUNT_TYPES.GIFT_WITH_PURCHASE) || null;
  const isProductExcludedFromLuxe = useIsBannedProduct(tags, luxeDiscountsInfo);
  const { luxe } = useContext(LuxeContext);
  const applyLuxeDiscountToProductPrice = useApplyLuxeDiscountToProductPrice();
  const formatWithCurrency = useCurrentFormatCurrency();

  const { isMobile } = useDeviceDetect();
  const dict = useLocalizedSentenceDict();

  const [infoPopupOpen, setInfoPopupOpen] = useState(false);

  const disableSubscriptionCookie = getCookie("subscription_disabled");
  const shouldDisableLuxe = !!disableSubscriptionCookie;

  const isGiftProduct =
    giftDiscount &&
    giftDiscount?.freegift?.products?.some(
      (giftProduct) => giftProduct.shopify.shopifyId === productId
    );

  const showLuxePrices =
    luxe.isLuxeEnabled &&
    !shouldDisableLuxe &&
    !isProductExcludedFromLuxe &&
    !LUXE_EXCLUDED_COMPONENTS.includes(componentType) &&
    !luxe.isForcedGuest &&
    !isGiftProduct;

  const luxeIcon =
    componentType === ComponentType.PDP || componentType === ComponentType.QUICKVIEW ? (
      <LuxeIcon width={isMobile ? "20" : "24"} className={Styles.price_container__luxeIcon} />
    ) : (
      <LuxeIcon height={isMobile ? "14" : "16"} className={Styles.price_container__luxeIcon} />
    );

  const { compareAtPrice, hasDiscount, finalPrice, fullPrice } = applyIgPrices(
    productId,
    selectedVariant,
    prices
  );

  const luxeFinalPrice = applyLuxeDiscountToProductPrice(
    parseFloat(finalPrice) + optionalProductsTotalPrice,
    tags
  );

  let discountText = null;
  const bogoDiscount = discountsInfo.find(({ type }) => type === DISCOUNT_TYPES.BOGO);
  if (
    bogoDiscount &&
    componentType !== ComponentType.BYOB &&
    componentType !== ComponentType.BYOB_PDP &&
    componentType !== ComponentType.QUICKVIEW &&
    !hasDiscount &&
    !(tags || []).some(
      (r) => bogoDiscount.matchType === "exclude" && bogoDiscount.matchTags.includes(r)
    )
  ) {
    discountText = dict.get(`BOGO {0}% OFF`, bogoDiscount?.percentageValue);
  }

  let wrapperClass = Styles.prices_cp;
  if (componentType === ComponentType.PDP || componentType === ComponentType.BYOB_PDP) {
    wrapperClass = Styles.prices_pdp;
  }

  if (componentType === ComponentType.PDP_ADS) {
    wrapperClass = Styles.prices_pdp_ads;
  }

  const fullPriceClass = [];
  if (hasDiscount) {
    fullPriceClass.push(Styles.price_container_before, "strikethrough", "dark-grey");
  }
  if (!compareAtPrice) {
    fullPriceClass.push(Styles.price_container_full_has_discount_right);
  }
  if (
    componentType === ComponentType.CP ||
    componentType === ComponentType.CP_ADS ||
    componentType === ComponentType.CP_OPTIONAL_PRODUCTS ||
    componentType === ComponentType.SEARCH ||
    componentType === ComponentType.BYOB ||
    componentType === ComponentType.FLOATING_ATC ||
    componentType === ComponentType.FLOATING_ATC_ADS ||
    componentType === ComponentType.WISHLIST
  ) {
    fullPriceClass.push(Styles.price_container_full_cp);
  }

  if (
    componentType === ComponentType.FLOATING_ATC ||
    componentType === ComponentType.FLOATING_ATC_ADS ||
    componentType === ComponentType.PDP_QUICK_UPSELL
  ) {
    fullPriceClass.push(Styles.price_container_full_floating, "subtext");
  }

  if (
    componentType === ComponentType.PRODUCT_CAROUSEL ||
    componentType === ComponentType.PDP_QUICK_UPSELL
  ) {
    fullPriceClass.push("subtext", "subtext--bold");
  }

  if (componentType === ComponentType.SIDE_CART_UPSELL) {
    fullPriceClass.push("text", "text--bold");
  }

  const rootClassNames = ["price_container", Styles.price_container];
  if (showLuxePrices) {
    rootClassNames.push(Styles.price_container_luxe);
  }
  if (
    componentType === ComponentType.PDP ||
    componentType === ComponentType.PDP_ADS ||
    componentType === ComponentType.BYOB_PDP ||
    componentType === ComponentType.QUICKVIEW
  ) {
    rootClassNames.push(Styles.price_container_pdp);
  }
  if (
    componentType === ComponentType.FLOATING_ATC ||
    componentType === ComponentType.FLOATING_ATC_ADS
  ) {
    rootClassNames.push(Styles.price_container_floating);
  }
  if (componentType === ComponentType.PRODUCT_CAROUSEL) {
    rootClassNames.push(Styles.price_container__product_carousel);
  }

  if (componentType === ComponentType.PDP_QUICK_UPSELL) {
    rootClassNames.push(Styles.price_container__pdp_quick_upsell);
  }

  const pricePercentageClassNames = [];
  pricePercentageClassNames.push(Styles.price_container_percentage);
  if (
    componentType === ComponentType.CP ||
    componentType === ComponentType.CP_ADS ||
    componentType === ComponentType.CP_OPTIONAL_PRODUCTS ||
    componentType === ComponentType.SEARCH ||
    componentType === ComponentType.BYOB ||
    componentType === ComponentType.WISHLIST
  ) {
    rootClassNames.push(Styles.price_container__cp);
    pricePercentageClassNames.push(Styles.price_container_percentage__productCollection);
  }

  if (
    componentType === ComponentType.FLOATING_ATC ||
    componentType === ComponentType.FLOATING_ATC_ADS
  ) {
    pricePercentageClassNames.push(Styles.floating__price_container_percentage);
  }

  // const [testLuxeFinalPrice, testFinalPrice, testFullPrice] = useMemo(() => {
  //   let nextLuxePrice = null;
  //   let nextFullPrice = null;
  //   let nextFinalPrice = null;
  //   let percentageOff = 0;
  //   if (
  //     discountInfo &&
  //     !(tags || []).some((t) => (discountInfo?.exclusionTags || []).includes(t))
  //   ) {
  //     if (discountStatus && (discountStatus.next || discountStatus.current)) {
  //       if (discountStatus.next) {
  //         percentageOff = discountStatus.next[1];
  //       } else {
  //         percentageOff = discountStatus.current[1];
  //       }
  //     }
  //   }

  //   const isOnlyExtraDiscount = (tags || []).some((r) => luxe?.extraDiscountTags?.includes(r));

  //   // Calculate (next) AL Luxe Price
  //   let nextPercentageOffLuxe = (luxe.discountLuxe || 0.05) * 100;
  //   if (!isOnlyExtraDiscount) {
  //     nextPercentageOffLuxe += percentageOff;
  //   }

  //   let wTestFullPrice = formatPriceAmount(
  //     parseFloat(fullPrice),
  //     gePriceDetails?.CurrencyCode,
  //     gePriceDetails?.CountryCode
  //   );
  //   let wTestFinalPrice = formatPriceAmount(
  //     parseFloat(finalPrice),
  //     gePriceDetails?.CurrencyCode,
  //     gePriceDetails?.CountryCode
  //   );
  //   let wTestLuxeFinalPrice = luxeFinalPrice;

  //   // Check if product is excluded from the sale
  //   const isExcluded = tags.some((r) => discountInfo?.luxeExclusionTags?.includes(r));
  //   nextLuxePrice = parseFloat(finalPrice) - parseFloat(finalPrice) * (nextPercentageOffLuxe / 100);
  //   // Calculate (next) Full Price
  //   nextFullPrice = parseFloat(fullPrice) - parseFloat(fullPrice) * (percentageOff / 100);
  //   // Calculate (next) Final Price
  //   nextFinalPrice = parseFloat(finalPrice) - parseFloat(finalPrice) * (percentageOff / 100);

  //   if (!isExcluded) {
  //     wTestLuxeFinalPrice = formatPriceAmount(
  //       parseFloat(nextLuxePrice),
  //       gePriceDetails?.CurrencyCode,
  //       gePriceDetails?.CountryCode
  //     );
  //     if (hasDiscount) {
  //       wTestFinalPrice = formatPriceAmount(
  //         parseFloat(nextFinalPrice),
  //         gePriceDetails?.CurrencyCode,
  //         gePriceDetails?.CountryCode
  //       );
  //     } else {
  //       wTestFullPrice = formatPriceAmount(
  //         parseFloat(nextFullPrice),
  //         gePriceDetails?.CurrencyCode,
  //         gePriceDetails?.CountryCode
  //       );
  //     }
  //   }

  //   return [wTestLuxeFinalPrice, wTestFinalPrice, wTestFullPrice];
  // }, [
  //   discountInfo,
  //   discountStatus,
  //   finalPrice,
  //   fullPrice,
  //   gePriceDetails?.CountryCode,
  //   gePriceDetails?.CurrencyCode,
  //   hasDiscount,
  //   luxe.discountLuxe,
  //   luxe?.extraDiscountTags,
  //   luxeFinalPrice,
  //   tags,
  // ]);

  if (pricesLoading) {
    return (
      <div
        className={`${
          componentType === ComponentType.CP || componentType === ComponentType.CP_ADS
            ? Styles.skeletonWrapper
            : ""
        }`}
      >
        <ALSkeleton width="31px" height="18px" />
      </div>
    );
  }

  if (!prices || prices?.price === undefined) {
    return null;
  }

  return (
    <div className={rootClassNames.join(" ")}>
      {showLuxePrices && (
        <div className={`${Styles.price_container__luxe} ${Styles[componentType.toLowerCase()]}`}>
          {luxeIcon}
          <p
            className={`${Styles.price_container__luxePrice} ${
              componentType === ComponentType.FLOATING_ATC ||
              componentType === ComponentType.FLOATING_ATC_ADS
                ? Styles.price_container_floating
                : ""
            }`}
            data-testid="luxe-price"
          >
            {formatWithCurrency(luxeFinalPrice)}
          </p>
          {componentType === ComponentType.PDP && (
            <button type="button" onClick={() => setInfoPopupOpen(!infoPopupOpen)}>
              <InfoIcon
                width={isMobile ? "15" : "20"}
                className={Styles.price_container__luxeIconInfo}
              />
            </button>
          )}
          <SubscriptionPopUp isOpen={infoPopupOpen} onClose={() => setInfoPopupOpen(false)} />
        </div>
      )}
      <div>
        <div className={wrapperClass}>
          {!isGiftProduct && fullPrice && (
            <p className={fullPriceClass.join(" ")} data-testid="fullPrice">
              {formatWithCurrency(parseFloat(fullPrice) + optionalProductsTotalPrice)}
            </p>
          )}

          {isGiftProduct && (
            <div className={Styles.price_container__gift}>
              <p className={`${fullPriceClass.join(" ")} strikethrough dark-grey`}>
                {formatWithCurrency(parseFloat(fullPrice))}
              </p>
              <GiftIcon
                fill="#FFFFFF"
                stroke="#2D2927"
                width={isMobile ? "16" : "20"}
                className={
                  componentType === ComponentType.CP || componentType === ComponentType.SEARCH
                    ? Styles.gift_icon
                    : ""
                }
              />
            </div>
          )}

          {hasDiscount && finalPrice && !isGiftProduct && (
            <p
              className={`subtext ${Styles.price_container_final} ${
                !luxe.isLuxeEnabled ? "poppy subtext--bold" : ""
              }`}
              data-testid="finalPrice"
            >
              {formatWithCurrency(parseFloat(finalPrice) + optionalProductsTotalPrice)}
            </p>
          )}
        </div>

        {discountText && (
          <div
            className={pricePercentageClassNames.join(" ")}
            data-discount={prices.discount_given}
            data-testid="discountText"
          >
            {discountText}
          </div>
        )}
      </div>
    </div>
  );
}

PriceContainer.propTypes = {
  componentType: PropTypes.string,
  optionalProductsTotalPrice: PropTypes.number,
  tags: PropTypes.arrayOf(PropTypes.string),
  prices: PropTypes.object,
  pricesLoading: PropTypes.bool,
};

export default withALErrorBoundary({
  name: "PriceContainer",
  priority: "P1",
})(PriceContainer);
