import { useMemo, useCallback, useContext, useState, useRef } from "react";
import { shuffle } from "lodash";

import { CartContext } from "../../context/CartProvider";
import useDiscountsInfo from "../../hooks/useDiscountsInfo";
import { useTracking } from "../../context/Tracking";

import FreeGiftProduct from "./freeGiftSelection/FreeGiftProduct";
import DISCOUNT_TYPES from "../../constants/DiscountTypes";
import { ALButton } from "../ALComponents";
import CloseIcon from "../icons/svgs/close-round.svg";

import * as Styles from "./freeGiftSelection/styles.module.scss";

function FreeGiftSelection({ lines, closeGiftSelection }) {
  const [selectedGift, setSelectedGift] = useState(null);

  const giftInCart = lines.find((line) =>
    line.attributes.find(({ key, value }) => key === "_free_gift" && value === "true")
  );

  const { addManyProductsToCart } = useContext(CartContext);
  const { trackFreeGiftSelection } = useTracking();
  const discountsInfo = useDiscountsInfo();

  const giftDiscount =
    (discountsInfo || []).find((s) => s.type === DISCOUNT_TYPES.GIFT_WITH_PURCHASE) || null;
  const freeGiftProducts = useMemo(() => giftDiscount?.freegift?.products || [], [giftDiscount]);
  const giftProductsRandomized = useRef(shuffle(freeGiftProducts));

  const handleConfirmationClick = useCallback(() => {
    const product = freeGiftProducts.find((p) => p.handle === selectedGift);

    const {
      shopify: { shopifyId, title, handle, productType, images, variants },
    } = product;
    const dataProductsToAdd = [
      {
        variant: {
          id: variants[0].id,
          sku: variants[0].sku,
          product: {
            title,
            id: shopifyId,
            handle,
            productType,
            images,
          },
        },
        quantity: 1,
        attributes: [{ key: "_free_gift", value: "true" }],
      },
    ];
    if (giftInCart) {
      dataProductsToAdd.push({
        ...giftInCart,
        quantity: 0,
      });
    }
    trackFreeGiftSelection({
      productId: product.shopify.shopifyId,
      productTitle: product.shopify.title,
      productHandle: product.handle,
    });
    addManyProductsToCart({ dataProductsToAdd });
    closeGiftSelection();
  }, [
    addManyProductsToCart,
    freeGiftProducts,
    selectedGift,
    giftInCart,
    closeGiftSelection,
    trackFreeGiftSelection,
  ]);

  return (
    <div className={Styles.freeGiftSelection}>
      <div className={Styles.freeGiftSelection__top}>
        <h3 className="h3 h3--uc h3--bold">Choose your gift</h3>
        <button
          onClick={closeGiftSelection}
          type="button"
          className={Styles.freeGiftSelection__close}
        >
          <CloseIcon fill="none" stroke="#2D2927" />
        </button>
      </div>
      <div className={Styles.freeGiftSelection__products}>
        {giftProductsRandomized?.current?.map((product) => (
          <FreeGiftProduct
            product={product}
            key={product.handle}
            selectedGift={selectedGift}
            setSelectedGift={setSelectedGift}
          />
        ))}
      </div>
      <div className={Styles.freeGiftSelection__bottom}>
        <ALButton onClick={handleConfirmationClick} disabled={!selectedGift}>
          Confirm selection
        </ALButton>
      </div>
    </div>
  );
}

export default FreeGiftSelection;
